<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="6">
          <CCardGroup>
            <CCard class="p-1 opacity_card">
              <CCardBody>
                <CForm>
                  <div class="float-right cont_logo">
                    <!-- <img  class="float-center imgresp" src="../../../public/template/logo_full_devs.png" width="60%" alt=""> -->
                  </div>
                  <h1 class="cont_logo">Ingreso al sistema</h1>
                  <p class="text-muted">&nbsp;</p>
                  <CInput placeholder="Usuario" autocomplete="Digite Dni o correo " v-model="login.login">
                    <template #prepend-content>
                      <CIcon name="cil-user" />
                    </template>
                  </CInput>
                  <CInput placeholder="Contraseña" type="password" autocomplete="curent-password"
                    v-model="login.password" @keypress.enter="mtd_login">
                    <template #prepend-content>
                      <CIcon name="cil-lock-locked" />
                    </template>
                  </CInput>
                  <CRow>
                    <CCol col="6" class="text-left">
                      <CButton color="primary" class="px-4" @click="mtd_login">Entrar</CButton>
                    </CCol>
                    <CCol col="6" class="text-right">
                      <!-- <CButton color="link" class="px-0">Forgot password?</CButton>
                      <CButton color="link" class="d-lg-none">Register now!</CButton> -->
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "Login",
  data() {
    return {
      url: "",
      login: {
        login: "",
        password: "",
      },
      
    };
  },
  created() {
    this.url = this.$store.getters.get__url;
  },
  methods: {
    ...mapActions(["post"]),
    mtd_login() {
      let hasError = false;

      if (this.login.login === "") {
        Swal.fire({
          icon: 'warning',
          title: 'Error',
          text: 'Digite usuario',
        });
        hasError = true;
      }

      if (this.login.password === "") {
        Swal.fire({
          icon: 'warning',
          title: 'Error',
          text: 'Digite contraseña',
        });
        hasError = true;
      }

      if (this.login.login === "" && this.login.password === "") {
        Swal.fire({
          icon: 'warning',
          title: 'Error',
          text: 'Rellene todos los campos',
        });
        hasError = true;
      }

      if (!hasError) {
        this.post({
          url: this.url + "/Authentication/login",
          params: this.login,
        })
          .then((response) => {
            if (response.statusCode === 200) {
              this.$store.commit("mt_set_auth", response.data.user);
              this.$store.commit("mt_set_dataroles", response.data.user.roles);
              this.$store.commit("mt_set_datacampus", response.data.campus);
              this.$store.commit("mt_set_datacompanies", response.data.companies);
              this.$router.push({ path: "home" });
            } else {
              if (response.data.error === "usuario_incorrecto") {
                Swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: 'Usuario incorrecto',
                });
              } else if (response.data.error === "contraseña_incorrecta") {
                Swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: 'Contraseña incorrecta',
                });
              } else {
                Swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: 'Error al iniciar sesión',
                });
              }
            }
          })
          .catch(() => {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Error en el servidor',
            });
          });
      }
    },
  }

};
</script>
<style scoped>
</style>
